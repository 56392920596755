import React from 'react'
import Typography from '@material-ui/core/Typography'

const Dashboard = () => {
  return (
      <div>
        <Typography variant='h1'>This is the dashboard</Typography>
      </div>
  )
}

export default Dashboard