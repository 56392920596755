const WaitListActionTypes = {
  LOAD_WAIT_LIST_START: 'LOAD_WAIT_LIST_START',
  LOAD_WAIT_LIST_SUCCESS: 'LOAD_WAIT_LIST_SUCCESS',

  ADD_CUSTOMER_TO_WAIT_START: 'ADD_CUSTOMER_TO_WAIT_START',
  ADD_CUSTOMER_TO_WAIT_SUCCESS: 'ADD_CUSTOMER_TO_WAIT_SUCCESS',
  ADD_CUSTOMER_TO_WAIT_FAILURE: 'ADD_CUSTOMER_TO_WAIT_FAILURE',

  UPDATE_CUSTOMER_IN_WAIT_START: 'UPDATE_CUSTOMER_IN_WAIT_START',
  UPDATE_CUSTOMER_IN_WAIT_SUCCESS: 'UPDATE_CUSTOMER_IN_WAIT_SUCCESS',
  UPDATE_CUSTOMER_IN_WAIT_FAILURE: 'UPDATE_CUSTOMER_IN_WAIT_FAILURE',


  REMOVE_CUSTOMER_FROM_WAIT: 'REMOVE_CUSTOMER_FROM_WAIT',
  REMOVE_CUSTOMER_FROM_WAIT_START: 'REMOVE_CUSTOMER_FROM_WAIT_START',
  REMOVE_CUSTOMER_FROM_WAIT_SUCCESS: 'REMOVE_CUSTOMER_FROM_WAIT_SUCCESS',
  REMOVE_CUSTOMER_FROM_WAIT_FAILURE: 'REMOVE_CUSTOMER_FROM_WAIT_FAILURE',

  SEND_WAIT_MESSAGE_START: 'SEND_WAIT_MESSAGE_START',
  SEND_WAIT_MESSAGE_SUCCESS: 'SEND_WAIT_MESSAGE_SUCCESS',
  SEND_WAIT_MESSAGE_FAILURE: 'SEND_WAIT_MESSAGE_FAILURE',

  RECEIVE_WAIT_LIST_DATA: 'RECEIVE_WAIT_LIST_DATA',
}

export default WaitListActionTypes