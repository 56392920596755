import createSagaMiddleware from 'redux-saga'
import logger from 'redux-logger'
import { applyMiddleware, createStore } from 'redux'
import { persistStore } from 'redux-persist'

import rootSaga from './root-saga'
import rootReducer from './root-reducer'

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger)
}

export const store = createStore(rootReducer, applyMiddleware(...middlewares))
sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store)

export default { store, persistor}